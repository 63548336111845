<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">{{ $t('clients') }}</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active">{{ $t('dashboard') }}</li>
                     <li class="breadcrumb-item active">{{ $t('clients') }}</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <!-- /.row -->
            <div class="row">
               <div class="col-12">
                  <div class="card">
                     <div class="card-header">

                        <div class="card-tools">
                           <div class="input-group input-group-sm pt-2" style="width: 350px;">
                              <input
                                 type="text"
                                 name="table_search"
                                 class="form-control float-right"
                                 placeholder="Buscar"
                                 v-model="searchQuery"
                                 @input="handleDelayedSearch">

                              <div class="input-group-append">
                                 <button type="submit" class="btn btn-default">
                                    <i class="fas fa-search"></i>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <!-- /.card-header -->

                     <div v-if="isLoading" class="d-flex justify-content-center">{{ $t('loading') }}</div>

                     <div v-else class="card-body table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                           <thead>
                           <tr>
                              <th>Nome</th>
                              <th>Email</th>
                              <th>Data de criação</th>
                              <th>Estado</th>
                           </tr>
                           </thead>
                           <tbody>
                              <tr v-for="(client ,index) in clients[currentPage - 1]" :key="index">
                                 <td>{{ client.name }}</td>
                                 <td>{{ client.email }}</td>
                                 <td>{{ client.createdAt }}</td>
                                 <td>{{ $t('payer') }}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <!-- /.card-body -->

                     <div class="card-footer clearfix">
                        <pagination
                           v-if="!isSearching"
                           :totalPages="totalPages"
                           :currentPage="currentPage"
                           :isFirstPage="isFirstPage"
                           :isLastPage="isLastPage"
                           :adjacentPages="adjacentPages"
                           :tokenPages="tokenPages"
                           :isLoading="isLoadingPagination"
                           @page-selected="handlePageSelected"
                        />
                     </div>
                     <!-- /.card-footer -->

                  </div>
                  <!-- /.card -->
               </div>
            </div>
            <!-- /.row -->
         </div>
      </section>
      <!-- /.content -->
   </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { useToast } from "vue-toastification";
import Pagination from "../../../components/Pagination.vue";

export default {
   name: "Clients",
   components: {Pagination},
   setup() {
      const toast = useToast()
      return { toast }
   },
   data(){
      return {
         isLoading: false,
         searchTimeout: null,
         searchQuery: "",
         isSearching: false,
         isLoadingPagination: false,
         adjacentPagesCount: 2,
      }
   },
   computed: {
      ...mapState({
         currentPage: state => state.user.clients.pagination.currentPage,
         clients: state => state.user.clients.data,
         tokenPages: state => state.user.clients.pagination.tokenPages,
         totalPages: state => state.user.clients.pagination.totalPages
      }),
      adjacentPages() {
         const currentIndex = this.tokenPages.findIndex((page) => page.page === this.currentPage);

         const startIdx = Math.max(0, currentIndex - this.adjacentPagesCount);
         const endIdx = Math.min(this.tokenPages.length - 1, currentIndex + this.adjacentPagesCount);

         const pages = this.tokenPages.slice(startIdx, endIdx + 1);

         return pages;
      },
      isFirstPage() {
         return this.currentPage === 1;
      },
      isLastPage() {
         return this.currentPage === this.totalPages;
      }
   },
   async created() {
      this.isLoading = true;
      this.isLoadingPagination = true;

      try {
         await this.getAllPaginatedUsers({ type: 'client' });

      } finally {
         this.isLoading = false;
         this.isLoadingPagination = false;
      }
   },
   methods: {
      ...mapActions(['getAllPaginatedUsers', 'searchUsers']),
      ...mapMutations({
         setCurrentPage: 'SET_CLIENT_CURRENT_PAGE'
      }),
      async handleDelayedSearch() {
         clearTimeout(this.searchTimeout);
         this.isLoading = true;

         this.searchTimeout = setTimeout(async () => {
            if (this.searchQuery.length > 0) {
               this.setCurrentPage(1);
               this.isSearching = true;
               await this.searchUsers({type: 'client', name: this.searchQuery});
               this.isLoading = false;
            } else {
               this.isSearching = false;
               await this.getAllPaginatedUsers({ type: 'client' })
               this.isLoading = false;
            }
         }, 1000);
      },
      async handlePageSelected (action) {
         this.isLoading = true;
         let pageIndex;

         if (action === 'first' && !this.isFirstPage) {
            pageIndex = 1;
         } else if (action === 'prev' && !this.isFirstPage) {
            this.setCurrentPage(this.currentPage - 1);
            pageIndex = this.currentPage;
         } else if (action === 'next' && !this.isLastPage) {
            this.setCurrentPage(this.currentPage + 1);
            pageIndex = this.currentPage;
         } else if (action === 'last' && !this.isLastPage) {
            pageIndex = this.totalPages;
         } else if (typeof action === 'number') {
            pageIndex = action;
         }

         if (pageIndex) {
            const targetPage = this.tokenPages.find((page) => page.page === pageIndex);

            if (targetPage) {
               this.setCurrentPage(targetPage.page);
               this.isLoading = false;
            }
         }
      }
   }
}
</script>

<style scoped>

</style>
